import React from "react"
import { Player, Controls } from "@lottiefiles/react-lottie-player"

const Animation = ({ json, autoplay = true }) => {
  return (
    <Player
      autoplay={autoplay}
      loop
      src={json}
      tw="w-full motion-reduce:hidden"
      id="lotti-contact"
    >
      <Controls visible={false} />
    </Player>
  )
}

export default Animation
