import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import React, { useState } from "react"
import ReactHtmlParser from 'react-html-parser';
import tw from "twin.macro"

const PersonList = ({ protagonists }) => {
  const [showAll, setShowAll] = useState(true)
  return (
    <>
      <div tw="mb-10 grid grid-cols-2 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {protagonists.map((protagonist, index) => (
          <div
            key={protagonist.id}
            tw="flex flex-col"
            css={[showAll ? tw`block` : tw`hidden`]}
          >
            <Link to={protagonist.uri} tw="mb-4">
              <figure>
                <div tw="relative mb-4 bg-sand-500 aspect-ratio aspect-ratio-1/1">
                  {protagonist?.featuredImage && (
                    <GatsbyImage
                      image={
                        protagonist.featuredImage?.node?.localFile
                          ?.childImageSharp?.gatsbyImageData
                      }
                      alt={protagonist.firstname}
                      tw="object-contain aspect-ratio-item"
                      load="lazy"
                    />
                  )}
                </div>
                <figcaption className="person">
                  <p tw="text-base font-bold sm:text-xl md:text-xl lg:text-2xl">
                    {ReactHtmlParser(protagonist.member.title)} {ReactHtmlParser(protagonist.member.firstname)} {ReactHtmlParser(protagonist.member.lastname)}
                  </p>
                  {protagonist.member.jobtitle && (
                    <p tw="text-sm">{ReactHtmlParser(protagonist.member.jobtitle)}</p>
                  )}
                </figcaption>
              </figure>
            </Link>
          </div>
        ))}
      </div>
    </>
  )
}

PersonList.propTypes = {}

export default PersonList
