import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const Spacing = ({
  children,
  defaultStyles = tw`mb-14 sm:mb-16 md:mb-20 lg:mb-24`,
  space = null,
  background = null,
  id,
  padding = false,
}) => {
  return (
    <div
      css={[
        //space === null && defaultStyles,
        space === "none" && tw`mb-0`,
        space === "extrasmall" && tw`mb-4 sm:mb-5 md:mb-6 lg:mb-10`,
        space === "small" && tw`mb-6 sm:mb-10 md:mb-14 lg:mb-16`,
        space === "base" && tw`mb-8 sm:mb-12 md:mb-16 lg:mb-20`,
        space === "medium" && tw`mb-14 sm:mb-16 md:mb-20 lg:mb-24`,
        space === "large" && tw`mb-20 sm:mb-22 md:mb-26 lg:mb-32`,
        space === "extralarge" && tw`mb-24 sm:mb-28 md:mb-32 lg:mb-40`,
        background && tw`bg-sand-500 py-12 md:pt-22 md:pb-20`,
        padding && tw`py-12 md:pt-22 md:pb-18`,
      ]}
      key={id}
    >
      {children}
    </div>
  )
}

Spacing.propTypes = {
  children: PropTypes.node,
  defaultStyles: PropTypes.object,
  space: PropTypes.string,
  id: PropTypes.string,
}

export default Spacing
