import React, { useState } from "react"
// import PropTypes from 'prop-types'
import EventTeaser from "./teaser"
import tw from "twin.macro"

const EventList = ({ events }) => {
  const [showAll, setShowAll] = useState(false)
  return (
    <>
      <ul tw="mb-11 border-b-3 border-black">
        {events &&
          events.map((event, index) => (
            <li
              tw="border-t-3 border-black py-14 md:px-6 lg:px-11"
              css={[showAll || index < 3 ? tw`block` : tw`hidden`]}
              key={`event-${event.id}`}
            >
              <EventTeaser {...event} />
            </li>
          ))}
      </ul>
      {/**<button
        onClick={() => setShowAll(!showAll)}
        tw="cursor-pointer bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
      >
        {showAll ? `Weniger` : `Mehr`}
      </button>**/}
    </>
  )
}

EventList.propTypes = {}

export default EventList
