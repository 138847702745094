import React from "react"
// import PropTypes from 'prop-types'
import twitter from "../../images/svgs/twitter.svg"
import "twin.macro"
import Icon from "../icon"

const   Twitter = props => {
  return (
    <a
      href="https://twitter.com/BSGleich"
      target="_blank"
      rel="noreferrer noopener"
      tw="flex h-11 w-11 items-center rounded-full p-2 text-anthracite-500 hover:text-lilac-500 focus:text-lilac-500 motion-safe:transition-colors motion-safe:duration-300"
      aria-label="Twitter"
    >
      <Icon svg={twitter} title="Twitter" tw="h-9 w-8 fill-current" />
    </a>
  )
}

Twitter.propTypes = {}

export default Twitter
