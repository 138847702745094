import React from "react"
//import Player from "./player"
import { Player, Controls } from "@lottiefiles/react-lottie-player"
import LottieJson from "/static/videos/240803_BSG_Gleichstellungstag_Icon_01.json"


const Animation = props => {
  return (
    <Player
      autoplay="true"
      {...props}
      loop
      src={LottieJson}
      id="lotti-speaker"
      background='transparent'
    >
      <Controls visible={false} />
    </Player>

  )
}

export default Animation
